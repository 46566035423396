import { useCallback } from "react";
import {
  selectMapDrawerIsOpen,
  mapOpenDrawer,
  mapCloseDrawer,
} from "redux/slices/shed/shedSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AppDrawer from "components/base-app/AppDrawer";
import MapCardReference from "components/map/MapCardReference";

const MapDrawer = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectMapDrawerIsOpen);

  const dispatchOpenDrawer = useCallback(
    () => dispatch(mapOpenDrawer()),
    [dispatch]
  );

  const dispatchCloseDrawer = useCallback(
    () => dispatch(mapCloseDrawer()),
    [dispatch]
  );

  return (
    <AppDrawer
      showOnlyOnMobile
      isOpen={Boolean(isDrawerOpen)}
      onClose={dispatchCloseDrawer}
      onOpen={dispatchOpenDrawer}
    >
      <MapCardReference />
    </AppDrawer>
  );
};

export default MapDrawer;
