import { useCallback } from "react";
import { CarState } from "types/cars";
import { MapItinerary } from "types/map";
import {
  addUserTrip,
  selectReferenceTrip,
  clearItinerary,
  updateMyUserTrips,
  selectReferenceCar,
} from "redux/slices/shed/shedSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useSaveTrip from "hooks/trips/useSaveTrip";
import { getNeutralTrip } from "libraries/trip";
import useDisplayMessage from "./useDisplayMessage";

type Props = {
  itinerary: MapItinerary | null;
  onSaved: (tripId: string, carId: string) => void;
};

type ReturnType = {
  handleSaveItineraryToTrip: (tripCar: CarState) => void;
  loading: boolean;
};

const useSaveItineraryToTrip = ({ onSaved, itinerary }: Props): ReturnType => {
  const dispatch = useAppDispatch();

  const { displayMessage } = useDisplayMessage();
  const referenceCar = useAppSelector(selectReferenceCar);

  const { handleSaveTrip, loading } = useSaveTrip();

  const referenceTrip = useAppSelector(selectReferenceTrip);

  const handleSaveItineraryToTrip = useCallback(async () => {
    if (!itinerary) {
      displayMessage("Remember to add a trip", "info");
      return;
    }

    const addedTrip = await handleSaveTrip({
      ...getNeutralTrip(),
      carId: referenceCar?.id ?? "",
      genericVin: referenceCar?.genericVin ?? "",
      mapTravelMode: referenceTrip.mapTravelMode,
      distanceMeters: itinerary.distanceMeters,
      distanceLabel: itinerary.distanceLabel,
      durationSeconds: itinerary.durationSeconds,
      fromAddress: itinerary.locationFrom.formattedAddress ?? "",
      fromLatitude: itinerary.locationFrom.latitude ?? 0,
      fromLongitude: itinerary.locationFrom.longitude ?? 0,
      licensePlate: referenceCar?.licensePlate ?? "",
      toAddress: itinerary.locationTo.formattedAddress ?? "",
      toLatitude: itinerary.locationTo.latitude ?? 0,
      toLongitude: itinerary.locationTo.longitude ?? 0,
      tripId: referenceTrip.tripId ?? "",
      commuteFrequency: referenceTrip.commuteFrequency,
    });

    if (addedTrip) {
      // Add new trip
      !referenceTrip.tripId && dispatch(addUserTrip(addedTrip));

      // Update existing trip
      referenceTrip.tripId && dispatch(updateMyUserTrips(addedTrip));

      dispatch(clearItinerary());

      onSaved(addedTrip.tripId ?? "", referenceCar?.id ?? "");
    }
  }, [
    handleSaveTrip,
    onSaved,
    dispatch,
    itinerary,
    referenceTrip,
    displayMessage,
    referenceCar,
  ]);

  return { handleSaveItineraryToTrip, loading };
};

export default useSaveItineraryToTrip;
