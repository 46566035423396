import { gql } from "@apollo/client";

export const GET_MY_USER = gql`
  query GetMyUser {
    myUser {
      trips {
        carId
        genericVin
        licensePlate
        mapTravelMode
        tripTimeStamp
        distanceMeters
        distanceLabel
        durationSeconds
        fromAddress
        fromLatitude
        fromLongitude
        toAddress
        toLatitude
        toLongitude
        tripId
        commuteFrequency
      }
      cars {
        id
        licensePlate
        genericVin
        carBrand
        carModel
        emissionCO2
        emissionType
        nextControl
        previousControl
      }
      offsets {
        carBrand
        carId
        co2Footprint
        distance
        donationAmount
        orderId
      }
    }
  }
`;

export const GET_CAR_PAYMENTS = gql`
  query getCarPayments($carId: String) {
    carOffsets(carId: $carId) {
      carId
      distance
      donationAmount
    }
  }
`;

export const GET_CAR_TRIPS = gql`
  query myCarTrips($carId: String) {
    myCarTrips(carId: $carId) {
      carId
      distanceLabel
      durationSeconds
      fromAddress
      fromLatitude
      fromLongitude
      toAddress
      toLatitude
      toLongitude
      distanceMeters
    }
  }
`;

export const GET_CAR = gql`
  query getCarStorage($id: String) {
    getCarStorage(id: $id) {
      id
      licensePlate
      genericVin
      carBrand
      carModel
      emissionCO2
      nextControl
      previousControl
    }
  }
`;

export const SIGNIN_EMAIL_AND_PASSWORD = gql`
  query LoginWithEmailAndPassword($email: String, $password: String) {
    loginWithEmailAndPassword(email: $email, password: $password) {
      code
      refreshToken
      token
      uid
    }
  }
`;

export const SIGNIN = gql`
  query Signin($email: String, $redirectUrl: String, $device: String) {
    signInCreateLink(email: $email, redirectURL: $redirectUrl, device: $device)
  }
`;

export const CONFIRM_PASSWORD_RESET = gql`
  mutation confirmPasswordReset($oobCode: String!, $newPassword: String!) {
    confirmPasswordReset(oobCode: $oobCode, newPassword: $newPassword)
  }
`;

export const ADD_NEW_USER = gql`
  mutation addUser($username: String!, $password: String!) {
    addUser(username: $username, password: $password)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const CONFIRM_SIGNIN = gql`
  query ConfirmSignin($email: String, $urlParams: String) {
    confirmSignin(email: $email, urlParams: $urlParams) {
      token
      refreshToken
      uid
    }
  }
`;

export const SEARCH_CAR = gql`
  query SearchCar($licensePlate: String) {
    searchCar(licensePlate: $licensePlate) {
      id
      genericVin
      licensePlate
      carBrand
      carModel
      previousControl
      nextControl
      emissionCO2
      emissionType
    }
  }
`;

// Amount is default 50 and number is test number provided by vipps
// Nothing is returned from capturePayment to frontend at this point
export const CAPTURE_PAYMENT = gql`
  query capturePayment(
    $mobileNumber: String = "97567861"
    $fallbackUrl: String = "shed-app.no"
    $donationAmount: Float
    $carId: String
    $tripId: String
    $licensePlate: String
    $carBrand: String
    $distance: Float
    $co2FootprintInKg: Float
  ) {
    capturePayment(
      fallbackUrl: $fallbackUrl
      payment: {
        mobileNumber: $mobileNumber
        donationAmount: $donationAmount
        carId: $carId
        tripId: $tripId
        licensePlate: $licensePlate
        carBrand: $carBrand
        distance: $distance
        co2Footprint: $co2FootprintInKg
      }
    ) {
      status
      orderId
      urlRedirect
    }
  }
`;

export const SAVE_CAR = gql`
  mutation SaveCar($car: CarInput) {
    addCar(car: $car) {
      id
      licensePlate
      genericVin
      carBrand
      carModel
      previousControl
      nextControl
      emissionCO2
      emissionType
    }
  }
`;

export const DELETE_TRIP = gql`
  mutation DeleteTrip($tripId: String!) {
    deleteTrip(tripId: $tripId)
  }
`;

export const SAVE_TRIP = gql`
  mutation SaveTrip($trip: TripInput) {
    addTrip(trip: $trip) {
      carId
      genericVin
      tripId
      mapTravelMode
      licensePlate
      distanceMeters
      distanceLabel
      durationSeconds
      fromAddress
      fromLatitude
      fromLongitude
      toAddress
      toLatitude
      toLongitude
      tripId
      commuteFrequency
    }
  }
`;

export const GENERATE_NEW_TOKEN = gql`
  mutation generateNewToken($refreshToken: String) {
    generateNewToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
