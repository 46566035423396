import { SyntheticEvent, useCallback } from "react";
import { ButtonBase, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "redux/hooks";
import { selectReferenceMarker } from "redux/slices/shed/shedSlice";
import { mapNavigateToCard, getMarkerCardImageSrc } from "libraries/map";

const MapCardReference = () => {
  const referenceMarker = useAppSelector(selectReferenceMarker);

  const handleNavigateToCard = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      referenceMarker && mapNavigateToCard(referenceMarker);
    },
    [referenceMarker]
  );

  return referenceMarker ? (
    <Stack>
      <Button
        onClick={handleNavigateToCard}
        startIcon={<SearchIcon />}
        color="secondary"
      >
        View Card
      </Button>
      <ButtonBase
        color="secondary"
        title={referenceMarker.name}
        onClick={handleNavigateToCard}
      >
        <img
          style={{ borderRadius: 8, width: "100%" }}
          src={getMarkerCardImageSrc(referenceMarker)}
          alt={referenceMarker.name}
        />
      </ButtonBase>
    </Stack>
  ) : null;
};

export default MapCardReference;
