import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { LatLng } from "types/map";
import { selectMapPositionTracker } from "redux/slices/shed/shedSlice";

type ReturnType = {
  trackedPosition: LatLng | null;
};

const useTrackedPosition = (): ReturnType => {
  const [watcherInitialized, setWatcherInitialized] = useState(false);
  const [trackedPosition, setTrackedPosition] = useState<LatLng | null>(null);
  const mapPositionTracker = useAppSelector(selectMapPositionTracker);

  useEffect(() => {
    if (!navigator.geolocation || !mapPositionTracker || watcherInitialized) {
      return;
    }

    setWatcherInitialized(true);
    console.log("[useTrackedPosition] Enable geo-location watcher");
    const watchId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        setTrackedPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.warn("[useTrackedPosition] Error watching position: ", error);
      }
    );

    // Cleanup the watch position when the component unmounts
    return () => {
      console.error("[useTrackedPosition] Disable geo-location watcher");
      navigator.geolocation.clearWatch(watchId);
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [mapPositionTracker]);

  return { trackedPosition };
};

export default useTrackedPosition;
