import { Box, Button } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import { useBoolean } from "../../hooks/core/useBoolean";
import { TripState } from "types/user";
import { CarState } from "types/cars";
import AppDrawer from "components/base-app/AppDrawer";
import CarDonation from "components/cars/CarDonation";
import { useAppDispatch } from "redux/hooks";
import { registerReferenceTripSelection } from "redux/slices/shed/shedSlice";

type Props = {
  trip: TripState;
  tripCar: CarState;
};

const TripDonate = ({ trip, tripCar }: Props) => {
  const dispatch = useAppDispatch();

  const {
    value: isDonateOpen,
    setTrue: openDonate,
    setFalse: closeDonate,
  } = useBoolean(false);

  const openDrawer = () => {
    dispatch(registerReferenceTripSelection(trip));
    openDonate();
  };

  return (
    <Box>
      <Button
        color="success"
        variant="contained"
        disableElevation
        startIcon={<ParkIcon />}
        onClick={openDrawer}
      >
        Donate
      </Button>
      <AppDrawer
        showOnlyOnMobile={false}
        isOpen={isDonateOpen}
        onClose={closeDonate}
        onOpen={openDonate}
      >
        <CarDonation trip={trip} tripCar={tripCar} />
      </AppDrawer>
    </Box>
  );
};

export default TripDonate;
