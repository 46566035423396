import { useSearchParams } from "react-router-dom";
import usePostMount from "hooks/core/usePostMount";
import {
  registerReferenceCar,
  registerReferenceTripLocation,
  registerReferenceExperienceId,
  registerReferenceTripTravelMode,
} from "redux/slices/shed/shedSlice";
import { TripShare } from "types/user";
import { CarGenericState } from "types/cars";
import { TravelMode, EXPERIENCE_ID } from "types/map";
import { useAppDispatch } from "redux/hooks";
import genericCardsEU from "libraries/genericEU.json";
import { genericCarToCar } from "libraries/car";
import { find } from "lodash/fp";

const useMapShareTripParams = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  usePostMount({
    handler: () => {
      const tripShare: TripShare = {
        travelMode: (searchParams.get("travelMode") ?? "") as TravelMode,
        genericVin: searchParams.get("genericVin") ?? "",
        fromLabel: searchParams.get("fromLabel") ?? "",
        fromCoordinates: searchParams.get("fromCoordinates") ?? ";",
        toLabel: searchParams.get("toLabel") ?? "",
        toCoordinates: searchParams.get("toCoordinates") ?? ";",
        experienceId: (searchParams.get("experienceId") ?? "") as EXPERIENCE_ID,
      };

      console.log("[Map] Shared trip consummed", tripShare);

      const fromLatitude = Number(tripShare.fromCoordinates.split(";")[0] || 0);
      const fromLongitude = Number(
        tripShare.fromCoordinates.split(";")[1] || 0
      );

      const toLatitude = Number(tripShare.toCoordinates.split(";")[0] || 0);
      const toLongitude = Number(tripShare.toCoordinates.split(";")[1] || 0);

      dispatch(
        registerReferenceTripLocation({
          fromAddress: tripShare.fromLabel ?? "",
          fromLatitude,
          fromLongitude,
          toAddress: tripShare.toLabel ?? "",
          toLatitude,
          toLongitude,
        })
      );
      const genericCar = find(
        ({ genericVin }: CarGenericState) => genericVin === tripShare.genericVin
      )(genericCardsEU);

      genericCar && dispatch(registerReferenceCar(genericCarToCar(genericCar)));

      tripShare.travelMode &&
        dispatch(registerReferenceTripTravelMode(tripShare.travelMode));

      tripShare.experienceId &&
        dispatch(registerReferenceExperienceId(tripShare.experienceId));
    },
  });
};

export default useMapShareTripParams;
