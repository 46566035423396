import { useMediaQuery, useTheme } from "@mui/material";

type ReturnType = boolean;

const useIsMobile = (): ReturnType => {
  const theme = useTheme();
  const mediaMatchesMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return mediaMatchesMobile;
};

export default useIsMobile;
