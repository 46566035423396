import { useState } from "react";
import MapApplicationMenu from "components/map/MapApplicationMenu";
import MapPanelContainer from "components/map/MapPanelContainer";
import MapContainer from "components/map/MapContainer";
import useDirections from "hooks/maps/useDirections";
import useNavLocation from "hooks/maps/useNavLocation";
import { LatLng } from "types/map";
import MapDrawer from "components/map/MapDrawer";
import { useAppSelector } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";
import useMapShareTripParams from "hooks/maps/useMapShareTripParams";
import RainforestPopupInfo from "components/awareness/RainforestPopupInfo";
import CarNoEmissionPopup from "components/cars/CarNoEmissionPopup";
import { useBoolean } from "hooks/core/useBoolean";

const MapPage = () => {
  useMapShareTripParams();

  const { value: rainforestOpen, setFalse: closeRainforest } = useBoolean(true);
  const [centerGeo, setCenterGeo] = useState<LatLng>({ lat: 0, lng: 0 });
  const [zoomLevel, setZoomLevel] = useState(3);
  const {
    fromAddress,
    fromLatitude,
    fromLongitude,
    toAddress,
    toLatitude,
    toLongitude,
  } = useAppSelector(selectReferenceTrip);

  useDirections({
    from: {
      formattedAddress: fromAddress,
      latitude: fromLatitude,
      longitude: fromLongitude,
    },
    to: {
      formattedAddress: toAddress,
      latitude: toLatitude,
      longitude: toLongitude,
    },
  });

  useNavLocation({
    onInitCenterGeo: setCenterGeo,
    onInitZoomLevel: setZoomLevel,
  });

  return (
    <>
      <MapContainer
        center={
          fromLatitude && fromLongitude
            ? {
                lat: fromLatitude ?? 0,
                lng: fromLongitude ?? 0,
              }
            : centerGeo
        }
        zoom={zoomLevel}
        disableDefaultUI
        zoomControl={false}
      >
        <CarNoEmissionPopup />
        <RainforestPopupInfo open={rainforestOpen} onClose={closeRainforest} />
      </MapContainer>
      <MapPanelContainer
        locationFrom={{
          formattedAddress: fromAddress,
          latitude: fromLatitude,
          longitude: fromLongitude,
        }}
        locationTo={{
          formattedAddress: toAddress,
          latitude: toLatitude,
          longitude: toLongitude,
        }}
      />
      <MapApplicationMenu />
      <MapDrawer />
    </>
  );
};

export default MapPage;
