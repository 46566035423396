import { useCallback, useState } from "react";
import { styled, Box, Paper, Stack } from "@mui/material";
import MapLocationField from "components/map/MapLocationField";
import { MapLocation } from "types/map";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import useCarSearch from "hooks/useCarSearch";
import CarBrandLoader from "components/cars/CarBrandLoader";
import CarSelectReference from "components/cars/CarSelectReference";
import {
  registerReferenceTripLocation,
  clearItinerary,
  clearTrip,
  selectTripTravelMode,
} from "redux/slices/shed/shedSlice";
import MapTripDetails from "components/map/MapTripDetails";
import MapToolbarCollapse from "components/map/MapToolbarCollapse";
import MapCardReference from "components/map/MapCardReference";
import MapSelectTravelMode from "components/map/MapSelectTravelMode";
import useIsMobile from "hooks/base-app/useIsMobile";

const PANEL_WIDTH_SM_PX = 300;
const PANEL_WIDTH_MD_PX = 400;

const PaperPanel = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    // Mobile viewport size
    top: 0,
    left: 0,
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    // Tablet and larger viewport size
    top: 0,
    left: 0,
    height: "100%",
    width: PANEL_WIDTH_SM_PX,
  },
  [theme.breakpoints.up("md")]: {
    width: PANEL_WIDTH_MD_PX,
  },
  borderRadius: 0,
  position: "fixed",
  padding: theme.spacing(1),
  overflow: "auto",
}));

type Props = {
  locationFrom: MapLocation;
  locationTo: MapLocation;
};

const MapPanelContainer = ({ locationFrom, locationTo }: Props) => {
  const [selectedValue, setSelectedValue] = useState("");

  const { smoothLoading, handleSearchCar } = useCarSearch();

  const [isMinimized, setIsMinimized] = useState(false);

  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const tripTravelMode = useAppSelector(selectTripTravelMode);

  const handleLocationFromChange = useCallback(
    (newFromLocation: MapLocation) => {
      console.log(
        "[Map] Change Location From",
        newFromLocation.formattedAddress
      );
      dispatch(
        registerReferenceTripLocation({
          fromLatitude: newFromLocation.latitude ?? 0,
          fromLongitude: newFromLocation.longitude ?? 0,
          fromAddress: newFromLocation.formattedAddress ?? "",
        })
      );
    },
    [dispatch]
  );

  const handleLocationToChange = useCallback(
    (newToLocation: MapLocation) => {
      console.log("[Map] Change Location Top", newToLocation.formattedAddress);
      dispatch(
        registerReferenceTripLocation({
          toLatitude: newToLocation.latitude ?? undefined,
          toLongitude: newToLocation.longitude ?? undefined,
          toAddress: newToLocation.formattedAddress ?? undefined,
        })
      );
    },
    [dispatch]
  );

  const toggleMinimize = useCallback(() => {
    setIsMinimized(!isMinimized);
  }, [isMinimized]);

  const handleClearTrip = useCallback(() => {
    setIsMinimized(false);
    dispatch(clearTrip());
    dispatch(clearItinerary());
  }, [dispatch]);

  return (
    <Box>
      <PaperPanel elevation={3}>
        <MapToolbarCollapse
          onClearTrip={handleClearTrip}
          isMinimized={isMinimized}
          onToggle={toggleMinimize}
        >
          <Stack width="100%" mt={1}>
            <MapSelectTravelMode />

            <MapLocationField
              label="From"
              location={locationFrom}
              onChange={handleLocationFromChange}
            />
            <MapLocationField
              label="To"
              location={locationTo}
              onChange={handleLocationToChange}
            />

            {tripTravelMode === "DRIVING" ? (
              <>
                {" "}
                <CarSelectReference
                  size="small"
                  onSearchCar={handleSearchCar}
                  onInputChange={setSelectedValue}
                  inputValue={selectedValue}
                />
                <CarBrandLoader loading={smoothLoading} />
              </>
            ) : (
              <Box mt={1} />
            )}
          </Stack>
        </MapToolbarCollapse>
        <MapTripDetails />
        {!isMobile && <MapCardReference />}
      </PaperPanel>
    </Box>
  );
};

export default MapPanelContainer;
