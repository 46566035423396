import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import DirectionsTransitIcon from "@mui/icons-material/DirectionsTransit";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { TravelMode } from "types/map";

const TripTravelModeIcon = ({ travelMode }: { travelMode: TravelMode }) => {
  return travelMode === "WALKING" ? (
    <DirectionsRunIcon />
  ) : travelMode === "BICYCLING" ? (
    <DirectionsBikeIcon />
  ) : travelMode === "TRANSIT" ? (
    <DirectionsTransitIcon />
  ) : null;
};

export default TripTravelModeIcon;
