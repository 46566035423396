import { useCallback, useContext } from "react";
import { LatLng } from "types/map";
import { MapContext } from "providers/MapProvider";
import { flow, first, get } from "lodash/fp";

type GeocodeAddress = {
  formatted_address: string;
  place_id: string;
};

type ReturnType = {
  decodeAddressFormat: (position: LatLng) => Promise<string>;
};

const getFirstAddress = flow(first, get("formatted_address"));

const useGeocoder = (): ReturnType => {
  const { asyncLibraryLoader } = useContext(MapContext);

  const decodeAddressFormat = useCallback(
    async (position: LatLng): Promise<string> =>
      new Promise(async (resolve, reject) => {
        try {
          const mapsLibraries = await asyncLibraryLoader;
          const geocoder = mapsLibraries && new mapsLibraries.Geocoder();

          if (!geocoder) {
            return await "";
          }

          geocoder.geocode(
            { location: position },
            (addresses: null | GeocodeAddress[], status) => {
              console.log(
                "[Geocoder] Location geocode status",
                status,
                addresses
              );
              resolve(addresses ? getFirstAddress(addresses) : "");
            }
          );
        } catch (error: unknown) {
          console.error(
            "[Geocoder] Error while initializing the Geocoder",
            error
          );
          reject("");
        }
      }),
    [asyncLibraryLoader]
  );

  return {
    decodeAddressFormat,
  };
};

export default useGeocoder;
