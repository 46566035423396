import {
  EXPERIENCE_ID,
  MapExperience,
  MapExperienceMarker,
  MapLocation,
  MapItinerary,
  GoogleDirectionResult,
} from "types/map";
import { flow, filter, first, get, pick } from "lodash/fp";
import experiences from "libraries/experiences.json";

export const neutralMapLocation = (): MapLocation => ({
  formattedAddress: "",
  latitude: null,
  longitude: null,
});

export const hasLatLong = ({ latitude, longitude }: MapLocation) =>
  Boolean(latitude && longitude);

export const toGoogleLatLong = (
  latitude: number | null,
  longitude: number | null
) => ({
  lat: latitude ?? 0,
  lng: longitude ?? 0,
});

const selectDistance = get("[0].distance");
const selectDuration = get("[0].duration");

export const mapDirectionResultParser = (
  directionResult: GoogleDirectionResult | null
) => {
  if (!directionResult) {
    console.error("[Library] mapDirectionResultParser - result empty");
    return {
      distanceMeters: 0,
      distanceLabel: "",
      durationSeconds: 0,
      googleDirection: null,
      bounds: null,
    };
  }

  return flow(
    get("routes[0]"),
    pick(["legs", "bounds"]),
    ({ legs, bounds }): Omit<MapItinerary, "locationFrom" | "locationTo"> => ({
      distanceMeters: selectDistance(legs)?.value ?? 0,
      distanceLabel: selectDistance(legs)?.text ?? "",
      durationSeconds: selectDuration(legs)?.value ?? 0,
      googleDirection: directionResult,
      bounds,
    })
  )(directionResult);
};

export const getExperienceById = (
  experienceId: EXPERIENCE_ID
): MapExperience | null =>
  flow(
    filter(["experienceId", experienceId]),
    first,
    (x: MapExperience) => x
  )(experiences) ?? null;

export const getMarkerCardImageSrc = ({
  cardId,
}: MapExperienceMarker): string =>
  `${process.env.PUBLIC_URL}/cards/${cardId}.png`;

export const mapNavigateToCard = ({ cardId }: MapExperienceMarker) => {
  const baseURL = process.env.REACT_APP_CARD_BASE_URL;

  console.log(`[Map] Navigate to card [${cardId}]`, baseURL);

  if (cardId && baseURL) {
    window.location.href = `${baseURL}${cardId}`;
  }
};
